.ant-modal-content {
    height: 100% !important;
    display: flex  !important;
    flex-direction: column  !important;
  }

  .ant-form-item-explain.ant-form-item-explain-error{
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;                                  
  }