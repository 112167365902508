@import "~antd/dist/antd.css";

.row-programlist>td:nth-child(1),
.row-programlist>td:nth-child(2),
.row-programlist>td:nth-child(6),
.row-programlist>td:nth-child(7),
.row-programlist>td:nth-child(8),
.row-programlist>td:nth-child(9),
.row-programlist>td:nth-child(10),
.row-programlist>td:nth-child(11),
.row-programlist>td:nth-child(13),
.row-programlist>td:nth-child(14),
.row-programlist>td:nth-child(15),
.row-programlist>td:nth-child(16),
.row-programlist>td:nth-child(17),
.row-programlist>td:nth-child(18),
.row-programlist>td:nth-child(19),
.row-programlist>td:nth-child(20),
.row-programlist>td:nth-child(21),
.row-programlist>td:nth-child(22) {
  text-align: center !important;
}

.row-programlist>td {
  height: 5px !important;
  padding: 6px !important;
}

/* * Program Details && Add Program*/

.fa-chevron-circle-left {
  color: #c0392b;
  font-size: 1.3em;
}

.ant-form-item-label {
  font-family: "Poppins-Medium";
}

.submit-button,
.submit-button:focus {
  background: #c0392b;
  color: white;
  font-family: "Railway-Bold";
  border: none;
  margin: auto;
  display: flex;
  width: 15em;
  justify-content: center;
  margin-top: 1em;
}

.submit-button:hover {
  background: #c0392b;
  color: #efd600;
}

Table {
  table-layout: fixed !important;
}

.ant-descriptions-title {
  text-align: center;
  font-size: 1em;
}

.ant-descriptions-view {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto
}



@media screen and (max-width: 768px) {

  /* * Audit Program Page */
  .audit-rows>td {
    line-height: 0.5em !important;
    padding: 7px 2em 0px 2em !important;
  }

  .audit-rows>td:nth-child(1),
  .audit-rows>td:nth-child(2),
  .audit-rows>td:nth-child(3),
  .audit-rows>td:nth-child(4) {
    text-align: center !important;
  }
}