@font-face {
  font-family: "Poppins-Regular";
  src: url('./assets/font/Poppins-Regular.ttf');
}
@font-face {
  font-family: "Poppins-Medium";
  src: url('./assets/font/Poppins-Medium.ttf');
}
@font-face {
  font-family: "Railway-Bold";
  src: url('./assets/font/Raleway-Bold.ttf');
}
@font-face {
  font-family: "Railway";
  src: url('./assets/font/Raleway-Medium.ttf');
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Poppins-Regular", "Open Sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 17px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
