@import "~antd/dist/antd.css";

#app {
  height: 100%;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}

/* * Program List*/

.ant-table-column-sorter {
  color: white;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: yellow !important;
}
.ant-table-column-sort {
  background-color: none !important;
}
th.ant-table-cell.ant-table-column-sort.ant-table-cell-fix-left.ant-table-column-has-sorters,
.ant-table-thead th.ant-table-column-sort,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-sort:hover {
  background: #e74c3c !important;
}

.fa-edit {
  color: #c0392b !important;
}

.fa-trash {
    color: #c0392b !important;
  }

.ant-table-tbody > tr > td {
  word-break: keep-all;
  white-space: nowrap;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(192, 57, 43, 0.25);
}
.ant-table-thead > tr > th {
  word-break: keep-all;
  white-space: nowrap;
  text-align: center !important;
  font-family: "Poppins-Medium";
  color: white;
  background: #e74c3c;
}

.row-programlist > td:nth-child(1),
.row-programlist > td:nth-child(2),
.row-programlist > td:nth-child(6),
.row-programlist > td:nth-child(7),
.row-programlist > td:nth-child(13),
.row-programlist > td:nth-child(14),
.row-programlist > td:nth-child(15),
.row-programlist > td:nth-child(16),
.row-programlist > td:nth-child(17),
.row-programlist > td:nth-child(18),
.row-programlist > td:nth-child(19),
.row-programlist > td:nth-child(20),
.row-programlist > td:nth-child(21),
.row-programlist > td:nth-child(22){
  text-align: center !important;
}

.row-territory > td {
  text-align: center !important;
  height: 5px !important;
  padding: 6px !important;
}

.row-game > td, .row-hurdle > td {
  text-align: center !important;
  height: 5px !important;
  padding: 6px !important;
}

.row-luckycode>td,

.row-programlist > td {
  height: 5px !important;
  padding: 6px !important;
}

.ant-card-head-title {
  font-family: "Railway-Bold";
}

/* * Program Details && Add Program*/

.fa-chevron-circle-left {
  color: #c0392b;
  font-size: 1.3em;
}

.ant-form-item-label {
  font-family: "Poppins-Medium";
}

.submit-button,
.submit-button:focus {
  background: #c0392b;
  color: white;
  font-family: "Railway-Bold";
  border: none;
  margin: auto;
  display: flex;
  width: 15em;
  justify-content: center;
}
.submit-button:hover {
  background: #c0392b;
  color: #efd600;
}
/* * Navigation Bar */
.user-icon {
  font-size: 3em;
  color: #f39c12;
}
.menu-user {
  margin-top: 2px;
  height: 30px !important;
  color: white;
}
.menu-user > span {
  color: #efd600;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu,
.ant-menu-submenu-popup > .ant-menu {
  top: 0px;
  color: white;
  font-family: "Railway-Bold";
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}
.ant-menu-submenu-title:hover,
.ant-menu-item:hover,
.ant-menu-submenu:hover,
.ant-menu-item-title:hover,
.ant-menu-item-active,
.ant-menu-submenu-active,
.ant-menu-item-open,
.ant-menu-submenu-open,
.ant-menu-item-selected,
.ant-menu-submenu-selected,
.ant-menu-item a:hover,
.ant-menu-title-content:hover {
  color: #efd600 !important;
}
.main-nav {
  width: 100%;
  border-bottom: none;
  box-shadow: none;
  position: fixed;
  z-index: 5;
  background: #c0392b !important;
}

.left-menu {
  background: #c0392b !important;
  border-bottom: none;
  box-shadow: none;
  float: left;
  width: 100%;
}
.right-menu.container {
  width: 100%;
}
.right-menu {
  background: #c0392b !important;
  border-bottom: none;
  box-shadow: none;
  width: auto;
  text-align: right;
  align-items: right;
  justify-content: right;
}

.ant-menu-submenu-popup > .ant-menu {
  background: #c0392b !important;
}
.ant-menu-item a {
  color: white !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:focus {
  background: none !important;
}

.side-menu {
  font-family: "Railway-Bold";
}

.ant-menu {
  background: none;
  border: none;
  box-shadow: none;
}

.ant-drawer-title,
.ant-menu-item-group-title,
.ant-menu-title-content {
  color: white;
}

/* * Welcome Page * */
.welcome-container {
  background: #c0392b;
  height: 100%;
  font-family: "Railway";
}

.welcome-container h1 {
  color: white;
  letter-spacing: 0.4em;
  font-size: 2.5em;
}

#loginBtn {
  background: none;
  border: none;
  box-shadow: none;
  float: right;
  color: #efd600;
  font-size: 1.2em;
}
#loginBtn:hover {
  color: white;
  border: none !important;
  box-shadow: none !important;
}
#loginBtn:active,
#loginBtn:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#loginBtn:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.site-layout {
  padding: 0 5em;
  margin-top: 4.5em;
  min-height: 380px;
}

.main-mobile {
  display: none;
}
.main-desktop {
  display: flex;
}

.ant-drawer-header,
.ant-drawer-body {
  background: linear-gradient(180deg, #c0392b 0%, #d12e20 60%);
}

@media screen and (max-width: 768px) {
  .btnTerritory {
    margin-top: 1em;
  }

  .btnGame, .btnHurdle {
    margin-top: 1em;
  }

  .modal-territory > .ant-modal-content {
    height: 350px !important;
  }

  .modal-territory-edit > .ant-modal-content {
    height: 300px !important;
  }

  .modal-game > .ant-modal-content {
    height: 720px !important;
  }

  .modal-game-edit > .ant-modal-content {
    height: 620px !important;
  }
  .modal-gametype > .ant-modal-content {
    height: 325px !important;
  }

.code-randomizer-rows>td {
  text-align: center !important;
  height: 5px !important;
  padding: 6px !important;
}

  .modal-hurdle > .ant-modal-content{
    height: 1000px !important;
  }

  .site-layout {
    padding: 0em;
    margin-top: 2.75em;
  }
  .main-desktop {
    display: none;
  }
  .main-mobile {
    display: flex;
    height: 3em;
  }

  #btnDrawer {
    height: 100%;
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
  }
  #btnDrawer:hover {
    border: none;
    color: #efd600;
  }
}

/* * Audit Program Page */
.audit-rows > td {
  line-height: 0.5em !important;
  padding: 7px 2em 0px 2em !important;
}
.audit-rows > td:nth-child(1),
.audit-rows > td:nth-child(2),
.audit-rows > td:nth-child(3),
.audit-rows > td:nth-child(4) {
  text-align: center !important;
}

.ant-table-cell > p {
  margin-top: 1em !important;
}

::-webkit-file-upload-button {
  display: none;
}

.ant-btn-danger {
  background-color: #e74c3c !important;
  cursor: pointer;
}

.btnTerritory > * {
  cursor: pointer !important;
}

.btnGame > * {
  cursor: pointer !important;
}

.modal-territory > .ant-modal-content {
  height: 360px;
}

.modal-territory-edit > .ant-modal-content {
  height: 300px;
}

.modal-game > .ant-modal-content {
  height: 410px;
}

.modal-game-edit > .ant-modal-content {
  height: 410px;
}

.modal-gametype-edit > .ant-modal-content {
  height: 185px;
}
.modal-gametype > .ant-modal-content {
  height: 250px;
}

.modal-hurdle > .ant-modal-content{
  height: 635px;
}


.ant-divider {
  color: #e74c3c !important;
  border-top: 1px solid rgba(216, 0, 0, 0.5);
}

.color {
  width: 35px;
  height: 20px;
  border-radius: 5px;
  padding: 10px !important;
}

.color-border {
  width: 37px;
  height: 22px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 1) !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;

  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;

  align-items: center !important;
  justify-content: center !important;
}

.swatch {
  background: #fff;
  border-radius: 1px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;

  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;

  align-items: center !important;
  justify-content: center !important;
}
